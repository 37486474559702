import React from 'react';
import './App.css';
import StartPage from "./Pages/Start/StartPage";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import SessionPage from "./Pages/Session/SessionPage";
import ReceiptPage from "./Pages/Receipt/ReceiptPage";
import {io} from "socket.io-client";
import ErrorPage, {ERROR_PAGE_TYPE} from "./Pages/Error/ErrorPage";

export const socket = io();
function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/vipps/:evseId" element={<StartPage/>}/>
                <Route path="/vipps/session/active/:orderId" element={<SessionPage/>}/>
                <Route path="/vipps/receipt/:orderId" element={<ReceiptPage/>}/>
                <Route path="/*" element={<Navigate to="/vipps" />} />
                <Route path="/vipps" element={<ErrorPage errorType={ERROR_PAGE_TYPE.NOT_FOUND}/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;