export enum Status{
    AVAILABLE = 0,
    BLOCKED = 1,
    CHARGING = 2,
    INOPERATIVE = 3,
    OUTOFORDER = 4,
    PLANNED = 5,
    REMOVED = 6,
    RESERVED = 7,
    UNKNOWN = 8,
    Faulted2
}
export interface Address {
    streetName: string;
    city: string;
    postalCode: string;
}

export interface Connector {
    id: string;
    smsCode: string;
    tariff: string;
    tariff_Unreg: string;
    connectorCategory: string;
    type: string;
    status: Status;
    maxEffect: number;
    startChargingScenario: string;
}

export interface Evs {
    id: string;
    name: string;
    connectors: Connector[];
}

export interface Location {
    id: string;
    name: string;
    address: Address;
    evse: Evs;
}

export interface Charger {
    location: Location;
    success: boolean;
    description: string;
    errorCode: string;
}



