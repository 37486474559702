import {useEffect, useState} from "react";
import {OrderSession} from "../../Model/Session";
import {getUTCNow} from "../../Common/Helper";
export const TIMER_INTERVAL_KEY = "TIMER_INTERVAL_ID";
export default function Timer({orderSession, dateTimeStarted}: { orderSession: OrderSession, dateTimeStarted: Date }) {

    const [timeCharged, setTimeCharged] = useState({diff: 0, timeElapsed: ""});

    function setTime() {
        const diff = Math.abs(getUTCNow() - new Date(orderSession.session.startDate).getTime());
        setTimeCharged({diff: diff, timeElapsed: millisToMinutesAndSeconds(diff)})
    }

    function millisToMinutesAndSeconds(millis: number) {
        const minutes = Math.floor(millis / 60000);
        const seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (+seconds < 10 ? '0' : '') + seconds;
    }

    useEffect(() => {
        const timerIntervalId = localStorage.getItem(TIMER_INTERVAL_KEY);
        if(timerIntervalId){
            clearInterval(+timerIntervalId)
        }
        setTime();
        const intervalId = window.setInterval(() => {
            setTime();
        }, 1000)
        localStorage.setItem(TIMER_INTERVAL_KEY, intervalId.toString());
    }, [dateTimeStarted])

    return (<div className="session-time">
        {timeCharged.timeElapsed}
    </div>);
}