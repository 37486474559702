import axios from "axios";
import {APP_URL} from "../Configuration";

export function getSession(orderId: string){
    return axios.get(`${APP_URL}/vipps/api/session/${orderId}`);
}

export function getPaymentStatus(orderId: string){
    return axios.get(`${APP_URL}/vipps/api/payment/${orderId}`);

}

export function stopSession(orderId: string){
    return axios.post(`${APP_URL}/vipps/api/session/${orderId}/stop`);
}

export function getCharger(evseId: string){
     return axios.get(`${APP_URL}/vipps/api/charger/${evseId}`);
}

export function createPaymentRequest(connectorId: string, amount: number, tariff: string,
                                     tariff_Unreg: string, maxEffect: number){
    return axios.post(`${APP_URL}/vipps/api/payments`,
    {
        connectorId: connectorId,
        isApp: true,
        amount: amount,
        fallBackUrl: `${APP_URL}/vipps/session/active`,
        tariff: tariff,
        tariff_Unreg: tariff_Unreg,
        maxEffect: maxEffect
    });
}

export function postFeedback(orderId: string, payload: any){
    return axios.post(`${APP_URL}/vipps/api/feedback/${orderId}`,
        payload);
}