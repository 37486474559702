

// On entering landing page
import {APP_URL} from "../Configuration";
import TagManager from "react-gtm-module";

export function setLocation(location: string){
    registerData({event: `pageview_${location}`})
}

// Selected plug. Will be fired when user press pay
export function registerPlug(plug: string){
    registerData({event: `plug_${plug}`})
}

// Web app successfully
export function registerCreatePaymentSuccess(){
    registerData({event: `payment_request_created_success`})
}

// If the web app fails to create a payment request to Frigg for some reason (Edge case)
export function registerCreatePaymentFailed(){
    registerData({event: `payment_request_created_failed`})
}


export function registerDownloadVippsApp(){
    registerData({event: `download_vipps_app`})
}




// SESSION SCREEN

// The payment was cancelled, or the user had insufficient funds on his/her account
export function registerPaymentCancel(){
    registerData({event: `payment_cancel`})
}

// The payment was successful
export function registerPaymentSuccess(){
    registerData({event: `payment_success`})
}

// User pressed stop charging
export function registerStopClicked(){
    registerData({event: `stop_clicked`})
}

// Charging failed to start, could be that the cable was not connected to the car
export function registerErrorChargerStart(){
    registerData({event: `error_charger_start`})
}

// Random error during charging
export function registerErrorCharger(){
    registerData({event: `error_charger`})
}


// Receipt page

export function onReceiptPageView(amount: number){
    registerData({event: `pageview_receipt`, amount: amount})
}

export function registerFeedbackFormOpened(){
    registerData({event: `feedback_form_opened`})
}

export function registerFeedbackSent(rating: number){
    registerData({event: `feedback_sent`, rating: rating})
}

export function registerReceiptDownloadApp(){
    registerData({event: `receipt_download_app`})
}

export function registerReceiptDownloadAppAfterFeedback(){
    registerData({event: `receipt_download_app_after_feedback`})
}

export function registerReceiptDownload(){
    registerData({event: `receipt_download`})
}


// Error page

export function registerSupportCall(){
    registerData({event: `support_call`})
}

function registerData(data: any){
    if(APP_URL.includes("https://charge.mer.eco")){
        TagManager.dataLayer({
            dataLayer: data,
        });
    }else{
        console.log(data);
    }
}