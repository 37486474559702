import './ReceiptPage.css';
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {MER_ORDER_ID, MER_STOP_PRESSED} from "../Start/StartPage";
import SmileySad from '../../assets/icon-smiley-bad.svg';
import SmileyAverage from '../../assets/icon-smiley-average.svg';
import SmileyGood from '../../assets/icon-smiley-good.svg';
import IconPhone from '../../assets/piggy-bank.svg';
import IconArrowRight from '../../assets/icon-arrow-right.svg';
import MerLogo from '../../assets/mer-logo.svg';
import HeartLogo from '../../assets/symbol-heart.svg';
import 'jspdf-autotable'
import {getSession, postFeedback} from "../../Api/Api";
import {OrderSession} from "../../Model/Session";
import ErrorPage, {ERROR_PAGE_TYPE} from "../Error/ErrorPage";
import {getMerConnectAppUrl} from "../../Common/AppstoreLink";
import {getReceiptPdf} from "../../Common/Pdf";
import {
    onReceiptPageView,
    registerFeedbackFormOpened,
    registerFeedbackSent, registerReceiptDownload,
    registerReceiptDownloadApp, registerReceiptDownloadAppAfterFeedback
} from "../../Api/GTM";
import {SESSION_PAYMENT_SUCCESS_SENT} from "../Session/SessionPage";

const enum SESSION_STATUS{
    INITIAL,
    LOADED,
    NOT_FOUND
}

const enum FEEDBACK_OPTION {
    BAD,
    AVERAGE,
    GOOD
}

const enum FEEDBACK_FORM_STATE {
    CLOSED,
    OPEN,
    SENT
}

export default function ReceiptPage() {
    const [orderSession, setOrderSession] = useState<{orderSession?: OrderSession, status: SESSION_STATUS}>
    ({orderSession: undefined, status: SESSION_STATUS.INITIAL});
    const [textAreaValue, setTextAreaValue] = useState<String>("");
    const [selectedFeedbackOption, setFeedbackOption] = useState<FEEDBACK_OPTION | undefined>();
    const [feedbackFormState, setFeedbackFormState] = useState(FEEDBACK_FORM_STATE.CLOSED);
    const history = useNavigate();


    let {orderId} = useParams<{ orderId: string }>();
    if (!orderId) {
        history("/vipps")
    }

    // Cleanup local storage
    localStorage.removeItem(MER_STOP_PRESSED);
    localStorage.removeItem(SESSION_PAYMENT_SUCCESS_SENT);


    useEffect(() => {
        if(orderId){
            getSession(orderId).then((response) => {
                if(localStorage.getItem(MER_ORDER_ID) && response.data.session.totalPrice.includeVat){
                    onReceiptPageView(response.data.session.totalPrice.includeVat);
                }
                localStorage.removeItem(MER_ORDER_ID);
                setOrderSession({orderSession: response.data, status: SESSION_STATUS.LOADED})
            }).catch(e => {
                localStorage.removeItem(MER_ORDER_ID);
                setOrderSession({orderSession: undefined, status: SESSION_STATUS.NOT_FOUND})
                }
            );
        }
    }, [orderId])

    function openFeedbackForm(feedbackOption: FEEDBACK_OPTION) {
        registerFeedbackFormOpened();
        setFeedbackOption(feedbackOption);
        setTimeout(() => {
            setFeedbackFormState(FEEDBACK_FORM_STATE.OPEN);
        }, 1000);
    }


    function getChargingTime() {
        if (orderSession.orderSession) {
            const from: Date = new Date(orderSession.orderSession.session.startDate);
            const to: Date = new Date(orderSession.orderSession.session.endDate)
            const diffMs = (to.getTime() - from.getTime());
            return Math.round(((diffMs % 86400000) % 3600000) / 60000);
        }
    }

    function getEndTimeFormatted(){

        let secs = Date.parse(orderSession.orderSession!.session.endDate.toString());
        let date = new Date(secs);
        date = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
            date.getUTCHours() + 1, date.getUTCMinutes(), date.getUTCSeconds()));

        let formattedDate = new Date(date).toLocaleDateString() + " "
            + new Date(date).toLocaleTimeString().split(":")[0] + ":" +
            new Date(date).toLocaleTimeString().split(":")[1];
        return formattedDate.replaceAll("/", ".");
    }
    function getEndDateFormatted(){
        return new Date(orderSession.orderSession?.session.endDate!).toLocaleDateString().replaceAll("/", ".");
    }

    function calculatePrice(tariff: string){

        const minutePrice = tariff.split("kr")[0]
        const startPrice = tariff.split("+")[1].split("kr")[0]
        const chargingTime = getChargingTime();
        let totalPrice = 0;
        if(chargingTime && minutePrice){
            totalPrice = +startPrice + (chargingTime * +minutePrice);
        }
        return totalPrice

    }

    function sendFeedback(){
        if(orderId){
            let rating = 821200002;
            if(FEEDBACK_OPTION.BAD.valueOf() === selectedFeedbackOption){
                rating = 821200000;
            }else if(FEEDBACK_OPTION.AVERAGE.valueOf() === selectedFeedbackOption){
                rating = 821200001;
            }
           if(orderId){
               postFeedback(orderId, {
                   "case": {
                       "type": "Vipps Feedback",
                       "orderId" : orderId,
                       "rating": rating,
                       "comment": textAreaValue
                   }
               }).then(res => {
                   registerFeedbackSent(rating);
                   setFeedbackFormState(FEEDBACK_FORM_STATE.SENT);
               }).catch(e => {
                   console.log(e)
               })
           }
        }
    }

    if(orderSession.status === SESSION_STATUS.NOT_FOUND){
        return <ErrorPage errorType={ERROR_PAGE_TYPE.SESSION_NOT_FOUND}/>
    }

    if(!orderSession.orderSession){
        return <></>
    }

    return (
        <div className={ feedbackFormState === FEEDBACK_FORM_STATE.SENT ? "res-container" : ""}>
            <div className="res-app">
                {feedbackFormState !== FEEDBACK_FORM_STATE.SENT &&
                    <div className="receipt">
                        <img src={MerLogo} alt="" className="logo"/>
                        <h1>{orderSession.orderSession?.session.totalPrice.includeVat}kr</h1>
                        <p className="tag">Takk for at du ladet med Mer</p>

                        <ul className="details">
                            <li className="details-sum">
                                <span>Total sum</span>
                                <span>{orderSession.orderSession?.session.totalPrice.includeVat} kr</span>
                            </li>
                            <li className="details-vat">Alle priser er inkludert MVA</li>
                            <li className="details-seperator"/>
                            <li className="details-facts">
                                <span>Total mottatt energi</span>
                                <span>{orderSession.orderSession?.session.kwh} kWh</span>
                            </li>
                            <li className="details-facts">
                                <span>Total ladetid</span>
                                <span>{getChargingTime()} min</span>
                            </li>
                            <li className="details-seperator-10"/>
                            <li className="details-small">
                                orderID: {orderId}
                            </li>
                            <li onClick={() => {
                                if(orderSession.orderSession && orderId){
                                    registerReceiptDownload();
                                    getReceiptPdf(orderSession.orderSession, orderId, getEndDateFormatted(), getEndTimeFormatted()).save('kvittering.pdf');
                                }
                            }} className="details-small details-small-link underline cursor-pointer ">
                                Last ned kvitteringen din her
                            </li>
                        </ul>
                    </div>
                }
                {feedbackFormState === FEEDBACK_FORM_STATE.SENT &&

                    <div className="flex flex-col items-center mb-10">
                            <img src={HeartLogo} alt="" className=""/>
                            <div className={"feedback-thanks " + (feedbackFormState === FEEDBACK_FORM_STATE.SENT ? "feedback-thanks--active": "")}>
                            <h2>Tusen takk!</h2>
                            <p>Din tilbakemelding hjelper oss å bli bedre.</p>
                        </div>
                    </div>

                }

                <div className="get-app-title">Få vår beste pris og kundefordeler! </div>
                <button onClick={() => {
                    if(feedbackFormState === FEEDBACK_FORM_STATE.SENT){
                        registerReceiptDownloadAppAfterFeedback();
                    }else{
                        registerReceiptDownloadApp();
                    }
                    window.location.href = getMerConnectAppUrl();
                }} className="get-app">
                        <img src={IconPhone} alt=""/>
                        <div className="flex flex-col">
                            {orderSession.orderSession.session.totalPrice.includeVat === 0 &&
                                <div>Du kunne spart 0 kr.</div>
                            }
                            {orderSession.orderSession.session.totalPrice.includeVat > 0 &&
                                <div>Du kunne spart {Math.round(calculatePrice(orderSession.orderSession.session.connector.tariff_Unreg) - calculatePrice(orderSession.orderSession.session.connector.tariff))} kr.</div>
                            }
                             <div>Last ned Mer Connect</div>
                        </div>
                        <img src={IconArrowRight} alt=""/>

                </button>



                <div className={"feedback " + (feedbackFormState !== FEEDBACK_FORM_STATE.SENT ? "feedback--active" : "")}>
                    <p>Hvordan var din ladeopplevelse<br/>med Mer og Vipps?</p>
                    <ul className="feedback-options">

                        <li onClick={() => {
                            openFeedbackForm(FEEDBACK_OPTION.BAD);
                        }}
                            className={selectedFeedbackOption === FEEDBACK_OPTION.BAD ? "feedback-option feedback-option--animate" : "feedback-option"}
                            data-demo="open-feedback">
                            <img src={SmileySad} alt="Dårlig erfaring"/>
                        </li>
                        <li onClick={() => {
                            openFeedbackForm(FEEDBACK_OPTION.AVERAGE);
                        }}
                            className={selectedFeedbackOption === FEEDBACK_OPTION.AVERAGE ? "feedback-option feedback-option--animate" : "feedback-option"}
                            data-demo="open-feedback">
                            <img src={SmileyAverage} alt="Grei erfaring"/>
                        </li>
                        <li onClick={() => {
                            openFeedbackForm(FEEDBACK_OPTION.GOOD);
                        }}
                            className={selectedFeedbackOption === FEEDBACK_OPTION.GOOD ? "feedback-option feedback-option--animate" : "feedback-option"}
                            data-demo="open-feedback">
                            <img src={SmileyGood} alt="God erfaring"/>
                        </li>

                    </ul>
                </div>
            </div>


            <div className={"feedback-form " +
                (feedbackFormState === FEEDBACK_FORM_STATE.OPEN ? "feedback-form--active" : "feedback-form--gone")}>

                {selectedFeedbackOption === FEEDBACK_OPTION.BAD &&
                    <>
                        <img src={SmileySad} alt="Dårlig erfaring"/>
                        <h2>Åh nei!</h2>
                        <p>Detta er ikke godt nok - fortell oss hva som gikk galt!</p>
                    </>
                }

                {selectedFeedbackOption === FEEDBACK_OPTION.AVERAGE &&
                    <>
                        <img src={SmileyAverage} alt="Gjennomsnittlig"/>
                        <h2>Hmmmm!</h2>
                        <p>Fortell oss gjerne hva vi kan bli enda bedre på</p>
                    </>
                }

                {selectedFeedbackOption === FEEDBACK_OPTION.GOOD &&
                    <>
                        <img src={SmileyGood} alt="Dårlig erfaring"/>
                        <h2>Fantastisk!</h2>
                        <p>Fortell oss gjerne hvorfor ladeopplevelsen var bra!</p>
                    </>
                }

                <textarea onChange={(event) => {
                    setTextAreaValue(event.target.value)
                }
                } className="feedback-msg" placeholder="Trykk her for å skrive meldingen"/>

                <div className="feedback-actions">
                    <button onClick={() => {
                        setFeedbackFormState(FEEDBACK_FORM_STATE.CLOSED)
                    }} className="feedback-cancel" data-demo="close-feedback">Avbryt
                    </button>
                    <button onClick={() => {
                        sendFeedback();
                    }} className="feedback-submit" data-demo="close-feedback">Send inn
                    </button>
                </div>
            </div>

        </div>
    );
}