import './SessionPage.css';
import CarLogo from '../../assets/bil.svg';
import {MER_EVSE_ID, MER_ORDER_ID, MER_STOP_PRESSED, RESERVE_AMOUNT} from "../Start/StartPage";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Timer from "./Timer";
import ConnectorIcon from "../Start/ConnectorIcon";
import {socket} from "../../App";
import {Socket} from "socket.io-client";
import {OrderSession} from "../../Model/Session";
import {getPaymentStatus, getSession, stopSession} from "../../Api/Api";
import ErrorPage, {ERROR_PAGE_TYPE} from "../Error/ErrorPage";
import {Payment} from "../../Model/Payment";
import {
    registerErrorCharger, registerErrorChargerStart,
    registerPaymentCancel,
    registerPaymentSuccess,
    registerStopClicked
} from "../../Api/GTM";
import useInterval from "../../Common/UseInterval";
import WaitingToStart from "./WaitingToStart";

export const SESSION_PAYMENT_SUCCESS_SENT = "SESSION_PAYMENT_SUCCESS_SENT";

export default function SessionPage() {
    const [orderSession, setOrderSession] = useState<{orderSession: OrderSession | undefined, received: Date}>({orderSession: undefined, received: new Date()});
    const history = useNavigate();
    const [currentSocket, setCurrentSocket] = useState<Socket | undefined>(undefined)
    const [error, setError] = useState(ERROR_PAGE_TYPE.NONE)
    const [stopClicked, setStopClicked] = useState(false)


    let {orderId} = useParams<{ orderId: string }>();
    if (!orderId) {
        const ord = localStorage.getItem(MER_ORDER_ID);
        orderId = ord ? ord : "";
    } else {
        localStorage.setItem(MER_ORDER_ID, orderId)
    }

    if (!orderId) {
        history("/vipps/" + localStorage.getItem(MER_EVSE_ID));
    }

    const onFocus = () => {
        if(window.location.href.includes("/session/active") &&
            (error === ERROR_PAGE_TYPE.NONE || error === ERROR_PAGE_TYPE.PAYMENT_INITIAL)){
            window.location.reload();
        }
    };

    useEffect(() => {
        window.addEventListener('focus', onFocus);
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('pageshow', onFocus);
        };
    });


    // Will keep track of when the last update was received. Updates should be received every 30 second - if more than
    // 45 seconds has passed, refresh the page
    useInterval(() => {
            const dif = new Date().getTime() - orderSession.received.getTime();
            const secondsSinceLastSessionUpdate = Math.abs(dif / 1000);
            if(secondsSinceLastSessionUpdate > 45){
                if(window.location.href.includes("/session/active") &&
                    (error === ERROR_PAGE_TYPE.NONE || error === ERROR_PAGE_TYPE.PAYMENT_INITIAL)){
                    window.location.reload();
                }
            }
    }, 5000);

    useEffect(() => {
        const stopped = localStorage.getItem(MER_STOP_PRESSED);
        if(stopped){
            setStopClicked(true);
        }
        getOrderSession(orderId)
    }, [orderId])


    useEffect(() => {
        setCurrentSocket(socket.on("session", (data: OrderSession) => {
            handleUpdatedSession(data)
        }));
        socket.emit("registerOrder", orderId);
    }, [currentSocket, orderId])


    function getOrderSession(orderId?: string){
        if(orderId){
            getSession(orderId).then((response) => {
                handleUpdatedSession(response.data)
            })
                .catch(function (errorMessage) {
                getPaymentStatus(orderId).then(res => {
                    const payment = res.data as unknown as Payment;
                    if(payment.transactionLogHistory.find(p => p.operation === 'VOID')){
                        registerErrorChargerStart();
                        setError(ERROR_PAGE_TYPE.CHARGER);
                    }
                    else if(payment.transactionLogHistory.find(p => p.operation === 'CANCEL')){
                        registerPaymentCancel();
                        navigateToStart();
                    }else{
                        setError(ERROR_PAGE_TYPE.PAYMENT_INITIAL)
                    }
                }).catch(function (error) {
                    setError(ERROR_PAGE_TYPE.SESSION_NOT_FOUND)
                })
            });
        }
    }

    function navigateToStart(){
        if(orderId){
            stopSession(orderId).then(res => {
                const evseId = localStorage.getItem(MER_EVSE_ID) || "";
                history(`/vipps/${evseId}`)
                }
            ).catch(e => {
                const evseId = localStorage.getItem(MER_EVSE_ID) || "";
                history(`/vipps/${evseId}`)
            });
        }
    }

    function handleUpdatedSession(session: OrderSession){
        // Send payment success only once
        if(!localStorage.getItem(SESSION_PAYMENT_SUCCESS_SENT)){
            localStorage.setItem(SESSION_PAYMENT_SUCCESS_SENT, "true");
            registerPaymentSuccess();
        }
        if(session.session.status === 'ACTIVE' || session.session.status === 'UPDATED' ){
            const startDate = session.session.startDate ? session.session.startDate :
                (session.session as any).start_date_time;
            if(startDate){
                setError(ERROR_PAGE_TYPE.NONE);
                session.session.startDate = startDate;
                setOrderSession({orderSession: session, received: new Date()});
            }
        }else if(session.session.status === 'COMPLETED'){
            history(`/vipps/receipt/${orderId}`)
        }else if(session.session.status === 'CANCELLED'){
            setError(ERROR_PAGE_TYPE.VIPPS);
        }
        else if(session.session.status === 'FAILED'){
            registerErrorCharger();
            setError(ERROR_PAGE_TYPE.CHARGER)
        }else{
            console.log("ERROR")
        }
    }

    function stop() {
        if(orderId){
            stopSession(orderId).then(res => {
                if(res.status === 200){
                 registerStopClicked();
                 localStorage.setItem(MER_STOP_PRESSED, "stopped")
                 setStopClicked(true);
                }
            });
        }
    }

    if(error === ERROR_PAGE_TYPE.PAYMENT_INITIAL || (orderSession.orderSession && !orderSession.orderSession.session.startDate)){
        return  <WaitingToStart navigateToStart={navigateToStart}/>
    }
    if(error !== ERROR_PAGE_TYPE.NONE){
        return <ErrorPage errorType={error}/>
    }
    if (!orderSession.orderSession) {
        return (<></>);
    }

    return (
        <>
            <div className="session-app">
                <h2>Lader</h2>
                <div className="session-charger-info">
                    <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ConnectorIcon type={orderSession.orderSession.session.connector.type} className={"session-charger-symbol-line"}/>
                    </svg>
                    <p>{orderSession.orderSession.session.connector.smsCode}</p>
                </div>

                <ul className="session-dots">
                    <li className="session-dot session-dot-1">&bull;</li>
                    <li className="session-dot session-dot-2">&bull;</li>
                    <li className="session-dot session-dot-3">&bull;</li>
                    <li className="session-dot session-dot-4">&bull;</li>
                </ul>

                <div className="session-car-animation">
                    <img src={CarLogo} alt="" className="session-car-symbol"/>
                    <div className="session-car-gradient-wrap">
                        <ul className="session-car-gradient">
                            <li className="session-car-gradient-1"/>
                            <li className="session-car-gradient-2"/>
                            <li className="session-car-gradient-3"/>
                        </ul>
                    </div>
                </div>

                {!stopClicked &&
                    <Timer orderSession={orderSession.orderSession} dateTimeStarted={orderSession.orderSession.session.startDate}/>
                }


                <div className="session-info">
                    <p>Beregnet energi: {orderSession.orderSession.session.kwh} kWt</p>
                    <p>Reservert beløp: {RESERVE_AMOUNT} kr</p>
                </div>
            </div>

            <div className={"session-stop-box" + (stopClicked ? " session-stop-box--active" : "")}>
                <button onClick={() => stop()} className="session-stop">
                    {stopClicked ? "Lading stopper..." : "Stopp lading"}
                </button>
            </div>
        </>
    );
}