import React from "react";

export default function useInterval(callback: any, delay: number) {
    const intervalRef: any = React.useRef();
    const callbackRef = React.useRef(callback);
    React.useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);
    React.useEffect(() => {
        intervalRef.current = window.setInterval(() => callbackRef.current(), delay);
        return () => window.clearInterval(intervalRef.current);
    }, [delay]);
    return intervalRef;
}