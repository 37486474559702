import jsPDF from "jspdf";
import {jsPDFDocument} from "jspdf-autotable";
import MerPdfLogo from "../assets/statkraft-mer-logo.png";
import {OrderSession} from "../Model/Session";


export function getReceiptPdf(orderSession: OrderSession, orderId: string,
                                 formattedEndDate: string, formattedEndTime: string){
    const doc: jsPDFDocument = new jsPDF()


    function getChargerSpeed(maxEffect: number){
        if(maxEffect <= 22){
            return 'Normal';
        }else if(maxEffect > 22 && maxEffect <= 50){
            return 'Hurtig';
        }else{
            return 'Superhurtig';
        }
    }


    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    const pageSize = doc.internal.pageSize
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    const paymentDetailsXCoordinate = pageWidth - 120;
    const infoText = doc.splitTextToSize("Tusen takk for at du velger Mer når du lader - her er din kvittering. Beløpet er belastet ditt betalingskort som du har registrert hos Vipps.", pageWidth - 35, {})
    const paymentMethodText = doc.splitTextToSize("Betalingsmetode: Vipps", pageWidth, {})
    const orderIdText = doc.splitTextToSize("Ordre ID: " + orderId, pageWidth, {})
    const dateText = doc.splitTextToSize("Kvittering utstedt: " + formattedEndDate, pageWidth, {})



    doc.setFontSize(11)
    doc.setTextColor(100)
    doc.text(paymentMethodText, paymentDetailsXCoordinate, (pageHeight / 6) + 5)
    doc.text(orderIdText, paymentDetailsXCoordinate, (pageHeight / 6) + 10)
    doc.text(dateText, paymentDetailsXCoordinate, (pageHeight / 6) + 15)


    doc.setFontSize(18)
    doc.setTextColor('#0017c8')
    doc.setFont(undefined, 'bold')
    doc.text('Kvittering på lading hos Mer',  14, (pageHeight / 5) + 35)
    doc.setFont(undefined, 'normal')
    doc.setFontSize(11)
    doc.setTextColor(100)
    doc.text(infoText, 14, (pageHeight / 5) + 45)


    const pdfLogo = new Image()
    pdfLogo.src = MerPdfLogo;
    // x,y,width, height
    doc.addImage(pdfLogo, 'png', pageWidth - 90, 15, 55, 25);

    doc.autoTable({
        styles: { fillColor: false, textColor: [0,0,0] , lineWidth: 0.1, lineColor: [0,0,0]},
        columnStyles: {
            0: { halign: 'left', fillColor: false } ,
            1: { halign: 'left', fillColor: false } ,
            2: { halign: 'left', fillColor: false } ,
            3: { halign: 'left', fillColor: false } ,
            4: { halign: 'left', fillColor: false } ,
            5: { halign: 'left', fillColor: false } ,
        },
        head: [
            {dato: 'Dato', beskrivelse: 'Beskrivelse', mva: 'Mva', exMva: 'Sum eks mva',mvaAmount: 'Mva-beløp', inclMva: 'Sum inkl mva'},
        ],
        body: [{
            dato: formattedEndDate,
            beskrivelse: `${formattedEndTime + " " + getChargerSpeed(orderSession.session.connector.maxEffect)} ${orderSession.session.kwh} kWh`,
            mva: "25,00%",
            exMva: `${orderSession.session.totalPrice.excludeVat}`,
            mvaAmount: `${Math.round((orderSession.session.totalPrice.includeVat - orderSession.session.totalPrice.excludeVat) * 100) / 100}`,
            inclMva: `${orderSession.session.totalPrice.includeVat}`,
        }],
        startY: (pageHeight / 5) + 55,
        showHead: 'firstPage',
    })


    var thankYouText = doc.splitTextToSize("Vi ønsker deg en flott dag videre - positiv energi fra alle oss i Mer", pageWidth - 35, {})
    doc.text(thankYouText, 14, 140)


    doc.setFillColor(28,0,183)
    // x, y
    doc.rect(15, pageHeight - 30, pageWidth - 30, 0.5, 'F');

    doc.setFontSize(7)
    doc.setFont(undefined, 'bold')
    doc.text('Mer Norway AS', 71, pageHeight - 22);
    doc.setFont(undefined, 'normal')
    doc.text('Kjøita 18, 4630 Kristiansand / Postadresse: Postboks 603 Lundsiden 4606 Kristiansand, Norge',  90, pageHeight - 22)
    doc.setFont(undefined, 'bold')
    doc.text('Org.Nr.',  128, pageHeight - 18)
    doc.setFont(undefined, 'normal')
    doc.text('994 768 832 mva / tlf. +47 47 67 08 00 / ',  137, pageHeight - 18)
    doc.setFont(undefined, 'bold')
    doc.text('no.mer.eco',  181, pageHeight - 18)


    return doc
    
}