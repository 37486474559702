import React, {useEffect, useState} from "react";
import Logo from '../../assets/mer-logo-stor.svg';
import VipsLogo from '../../assets/vipps-logo.svg';
import './StartPage.css';
import ConnectorListItem from "./ConnectorListItem";
import {Charger, Evs} from "../../Model/Charger";
import ConnectorIcon from "./ConnectorIcon";
import {useNavigate, useParams} from "react-router-dom";
import {createPaymentRequest, getCharger, getSession} from "../../Api/Api";
import ErrorPage, {ERROR_PAGE_TYPE} from "../Error/ErrorPage";
import {getVippsAppUrl} from "../../Common/AppstoreLink";
import {OrderSession} from "../../Model/Session";
import {
    registerCreatePaymentFailed,
    registerCreatePaymentSuccess,
    registerPlug,
    setLocation
} from "../../Api/GTM";
import {SESSION_PAYMENT_SUCCESS_SENT} from "../Session/SessionPage";

export const MER_ORDER_ID = "MER_SESSION_ID"
export const MER_EVSE_ID = "MER_EVSE_ID";
export const MER_STOP_PRESSED = "MER_STOP_PRESSED";
export const MER_START_CHARGING_SCENARIO = "";
export const RESERVE_AMOUNT = 450;
enum CHARGER_RESPONSE_STATUS {
    INITIAL,
    NOT_FOUND,
    FOUND,
    ERROR
}

export default function StartPage() {

    const [selectedConnectorId, setSelectedConnectorId] = useState(null);
    const [charger, setCharger] = useState<{ charger: Charger | undefined, status: CHARGER_RESPONSE_STATUS }>
    ({charger: undefined, status: CHARGER_RESPONSE_STATUS.INITIAL});
    const {evseId} = useParams<{ evseId: string }>();
    const history = useNavigate();


    // Cleanup local storage
    localStorage.removeItem(MER_STOP_PRESSED);
    localStorage.removeItem(SESSION_PAYMENT_SUCCESS_SENT);


    useEffect(() => {
        if (evseId) {
            getCharger(evseId).then((response) => {
                if (response.data.location && response.data.location.evse) {
                    const charger: Charger = response.data as Charger;
                    setCharger({charger: charger, status: CHARGER_RESPONSE_STATUS.FOUND})
                    localStorage.setItem(MER_EVSE_ID, evseId)
                    setLocation(charger.location.name)
                } else {
                    setCharger({charger: undefined, status: CHARGER_RESPONSE_STATUS.NOT_FOUND})
                }
            }).catch(error => {
                setCharger({charger: undefined, status: CHARGER_RESPONSE_STATUS.ERROR});

            })
        } else {
            setCharger({charger: undefined, status: CHARGER_RESPONSE_STATUS.NOT_FOUND})
        }
    }, [evseId])

    const evs: Evs | undefined = charger.charger?.location.evse;


    // Charger not loaded yet
    if (charger.status === CHARGER_RESPONSE_STATUS.INITIAL) {
        return <></>
    }

    // Charger wasn't found
    if (charger.status === CHARGER_RESPONSE_STATUS.NOT_FOUND) {
        return <ErrorPage errorType={ERROR_PAGE_TYPE.CHARGER_NOT_FOUND}/>;
    }

    // Connection failed
    if (charger.status === CHARGER_RESPONSE_STATUS.ERROR) {
        return <ErrorPage errorType={ERROR_PAGE_TYPE.CONNECTION_ERROR}/>;
    }


    if (!evs || !charger.charger) {
        return <></>;
    }

    // Check if there's an active session, and act upon that session
    const orderId = localStorage.getItem(MER_ORDER_ID);
    if (orderId) {
        getSession(orderId).then(res => {
            let session: OrderSession = res.data;
            if(session.session.status === 'COMPLETED'){
                localStorage.removeItem(MER_ORDER_ID);
                history(`/vipps/receipt/${orderId}`);
            }else{
                history(`/vipps/session/active/${orderId}`);
            }
        }).catch(e => {
            localStorage.removeItem(MER_ORDER_ID);
        })
    }


    function initiatePaymentRequest() {
        const selectedConnector = charger.charger?.location.evse.connectors.find(c => c.id === selectedConnectorId);
        if (evs && selectedConnectorId && selectedConnector) {
            registerPlug(selectedConnector.type.toLowerCase());
            createPaymentRequest(selectedConnectorId, RESERVE_AMOUNT,
                selectedConnector.tariff, selectedConnector.tariff_Unreg, selectedConnector.maxEffect).then(resp => {
                let response = resp.data;
                const orderID = response.orderId;
                localStorage.setItem(MER_ORDER_ID, orderID);
                localStorage.setItem(MER_START_CHARGING_SCENARIO, selectedConnector.startChargingScenario)
                window.location.href = response.url
                registerCreatePaymentSuccess();
                setTimeout(() => {
                    window.location.href = getVippsAppUrl();
                }, 5000)
            }).catch((e) => {

                registerCreatePaymentFailed();
            })
        }
    }

    return (
        <div className="app">
            <header className="location">
                <div className="location-content">
                    <h2>Drop-in med Vipps</h2>
                    <address>{charger.charger.location.name}</address>
                </div>
                <img src={Logo} alt="" className="location-logo"/>
            </header>


            <main className="choices">
                <section className="choice choice--charger">
                    <h3>Velg ladekontakt</h3>
                    <p>Priser er for drop-in med Vipps</p>

                    <div className={selectedConnectorId !== null ? "charger-box charger-box--active" : "charger-box"}>
                        <div className="charger-icon">
                            <svg width="39" height="32" viewBox="0 0 39 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M25 16H39V17H25V16Z  M26 27H23V11V9V5.8C23 4.3 21.7 3 20.2 3H11.8C10.3 3 9 4.3 9 5.8V9V11V27H6C5.7 27 5.5 27.2 5.5 27.5V28.5C5.5 28.8 5.7 29 6 29H9H23H26C26.3 29 26.5 28.8 26.5 28.5V27.5C26.5 27.2 26.3 27 26 27ZM14.6 15.4C14.6 15.3 14.7 15.2 14.9 15.2C15.1 15.2 15.2 15.2 15.3 15.3L16.4 16.4L19 13.8C19.1 13.7 19.3 13.7 19.5 13.7C19.6 13.8 19.7 14 19.7 14.1L17.6 20.5C17.6 20.6 17.5 20.7 17.3 20.7H17.2C17.1 20.7 17 20.7 16.9 20.6L15.8 19.5L13.2 22.1C13.1 22.2 13 22.2 12.9 22.2C12.8 22.2 12.8 22.2 12.7 22.1C12.5 22.1 12.4 21.9 12.5 21.8L14.6 15.4ZM11 5.8C11 5.4 11.4 5 11.8 5H20.2C20.6 5 21 5.4 21 5.8V9H11V5.8Z"
                                    className="charger-icon-line"/>
                            </svg>
                        </div>

                        <ul className="charger-options">
                            {evs.connectors.map((connector, index) => {
                                return <div key={index}>
                                    <ConnectorListItem connector={connector}
                                                       setSelectedChargerId={setSelectedConnectorId}
                                                       selected={connector.id === selectedConnectorId}/>
                                    {index < evs.connectors.length - 1 &&
                                        <li className="charger-seperator"/>
                                    }

                                </div>
                            })
                            }
                        </ul>
                    </div>
                </section>

                <section className="choice choice--cost">
                    <h3>Midlertidig reservasjonsbeløp kr {RESERVE_AMOUNT}</h3>
                    <p>Du vil kun bli belastet for beløpet du lader for</p>
                </section>
            </main>

            <div className="pay-box">
                <div className={selectedConnectorId ? "pay-box-info pay-box-info--ready" : "pay-box-info"}>
                    <svg width={28} height={28} viewBox="0 0 40 40" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <ConnectorIcon className={"pay-box-symbol-line"}
                                       type={evs.connectors.find(c => c.id === selectedConnectorId)?.type || ''}/>
                    </svg>
                    <div className="pay-box-text">
                        <h3>{selectedConnectorId ? evs.connectors.find(c => c.id === selectedConnectorId)?.smsCode : "Ingen ladepunkt valgt"}</h3>
                        <p>{selectedConnectorId ? 'Drop-in: ' + evs.connectors.find(c => c.id === selectedConnectorId)?.tariff_Unreg : "Beløpet reserveres og trekkes etter avsluttet lading."}</p>
                    </div>
                </div>
                <button
                    onClick={() => {
                        initiatePaymentRequest();
                    }}
                    className="vipps-button" disabled={selectedConnectorId == null}>
                      <span className="vipps-button-content">
                        Betal med <img src={VipsLogo} alt=""/>
                      </span>
                </button>
            </div>
        </div>
    );
}