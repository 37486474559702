import {getMobileOperatingSystem} from "./OperatingSystem";

export function getVippsAppUrl(){

    const mobileOperatingSystem = getMobileOperatingSystem();
    if(mobileOperatingSystem === "iOS"){
        return "https://apps.apple.com/us/app/vipps/id984380185";
    }else if(mobileOperatingSystem === 'Android'){
        return "https://play.google.com/store/apps/details?hl=en&id=no.dnb.vipps";
    }

    return "https://apps.apple.com/us/app/vipps/id984380185";

}

export function getMerConnectAppUrl(){

    const mobileOperatingSystem = getMobileOperatingSystem();
    if(mobileOperatingSystem === "iOS"){
        return "https://apps.apple.com/us/app/mer-connect/id1011180245";
    }else if(mobileOperatingSystem === 'Android'){
        return "https://play.google.com/store/apps/details?id=no.giantleap.gronn.kontakt&hl=en";
    }

    return "https://apps.apple.com/us/app/mer-connect/id1011180245";

}