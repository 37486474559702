import React from "react";
import './StartPage.css';
import {Connector, Status} from "../../Model/Charger";
import ConnectorIcon from "./ConnectorIcon";


export default function ConnectorListItem({connector, setSelectedChargerId, selected}:
                                     { connector: Connector, setSelectedChargerId: any, selected: boolean }) {


    let chargerCss = connector.status.toString() !== Status[Status.AVAILABLE] ? "charger-option--busy" : "";
    chargerCss = selected ? "charger-option--active" : chargerCss;


    return (
        <li onClick={() => {
            if (connector.status.toString() === Status[Status.AVAILABLE]) {
                setSelectedChargerId(connector.id)
            }
        }} className={"charger-option  " + chargerCss} data-demo="choose-charger">
            <div className="charger-id">{connector.smsCode}</div>
            <div className="charger-symbol">
                <svg width={24} height={24} viewBox="0 0 40 40" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <ConnectorIcon type={connector.type}/>
                </svg>

            </div>
            <div className="charger-info">
                <h4>{connector.type}</h4>
                { (
                    connector.status.toString() === Status[Status.OUTOFORDER]  ||
                    connector.status.toString() === Status[Status.INOPERATIVE] ||
                    connector.status.toString() === Status[Status.UNKNOWN] ||
                    connector.status.toString() === Status[Status.BLOCKED]
                    ) &&
                    <p>I ustand</p>
                }
                {connector.status.toString() === Status[Status.CHARGING] &&
                    <p>Opptatt</p>
                }
                {connector.status.toString() === Status[Status.AVAILABLE] &&
                    <p>{connector.tariff_Unreg}</p>
                }
            </div>
            <div className="charger-power"><span>{connector.maxEffect}</span><br/>kW</div>
            <div className="charger-check">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9 18C4.03714 18 0 13.9629 0 9C0 4.03714 4.03714 0 9 0C13.9629 0 18 4.03714 18 9C18 13.9629 13.9629 18 9 18ZM9 1.28571C4.74429 1.28571 1.28571 4.74429 1.28571 9C1.28571 13.2557 4.74429 16.7143 9 16.7143C13.2557 16.7143 16.7143 13.2557 16.7143 9C16.7143 4.74429 13.2557 1.28571 9 1.28571Z"
                        className="charger-check-circle"/>
                    <path
                        d="M12.8125 5.19376L7.10001 10.3375L5.66876 8.82501C4.62501 7.73751 2.93751 9.18126 3.84376 10.375C6.03751 13.2625 5.98126 13.2063 6.12501 13.3375C6.66251 13.8313 7.50001 13.7938 7.99376 13.2563L8.00001 13.25L14.1563 6.55001C14.9688 5.66251 13.7063 4.38751 12.8125 5.19376Z"
                        className="charger-check-mark"/>
                </svg>
            </div>
        </li>
    );
}