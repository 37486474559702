import WaitingLogo from "../../assets/clock.svg";
import Progressbar from "../../Components/Progressbar";
import LargeDisplayButton from '../../assets/instructions_large_display_button.svg';
import StartButton from '../../assets/instructions_press_button.svg';
import {MER_START_CHARGING_SCENARIO} from "../Start/StartPage";
export default function WaitingToStart({navigateToStart}: {navigateToStart: any}) {

    const startChargingScenario = localStorage.getItem(MER_START_CHARGING_SCENARIO);

    function getStartingScenarioText(){
        switch (startChargingScenario){
            case "1":
                return <>
                    <img src={LargeDisplayButton} className="w-1/2 h-1/2"/>
                    <h1 className="text-[#1200c2] text-[2.0rem] pb-6 pt-4 pl-4">Trykk på startknappen ved siden av ladedisplayet.</h1>
                </>;
            case "4":
                return <>
                    <img src={StartButton} className="w-1/2 h-1/2"/>
                    <h1 className="text-[#1200c2] text-[2.0rem] pb-6 pt-4 pl-4">Trykk på startknappen ved siden av ladedisplayet.</h1>
                </>;
            default:
                return <>
                    <img src={WaitingLogo} className="w-1/2 h-1/2"/>
                    <h1 className="text-[#1200c2] text-[2.5rem] pb-6"> Ladingen starter straks. </h1>
                    <h1 className="text-[#1200c2] text-[2.5rem] text-center pb-20">Dette kan ta litt tid - vær tålmodig!</h1>
                </>
        }

    }

   return <div className="flex flex-col bg-white items-center content-center">

       <div className="flex flex-col items-center justify-center w-screen h-[65vh]">
           {
               getStartingScenarioText()
           }

       </div>


       <div className="relative w-full">
           <Progressbar/>
       </div>
       <div className="flex flex-col items-center pt-10 justify-start gap-y-10 w-screen bg-[#1200c2]">
           <span className="text-white text-4xl p-6">Venter på å starte...</span>
           <div className="w-[95%]">
               <button onClick={() => {
                   navigateToStart();
               }
               } className="session-stop">
                   Avbryt
               </button>
           </div>
       </div>
   </div>;
}