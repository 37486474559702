import React from "react";
import './ErrorPage.css';
import Logo from "../../assets/mer-logo-stor.svg";
import CarCrashLogo from "../../assets/crash.svg";
import {getMerConnectAppUrl} from "../../Common/AppstoreLink";
import {MER_EVSE_ID, MER_ORDER_ID} from "../Start/StartPage";
import {APP_URL} from "../../Configuration";
import {registerSupportCall} from "../../Api/GTM";

export enum ERROR_PAGE_TYPE{
    NONE,
    CHARGER_NOT_FOUND,
    NOT_FOUND,
    SESSION_NOT_FOUND,
    VIPPS,
    CHARGER,
    PAYMENT_INITIAL,
    CONNECTION_ERROR,
}
export default function ErrorPage({errorType}: {errorType: ERROR_PAGE_TYPE}) {


    localStorage.removeItem(MER_ORDER_ID);


    return (
        <div className="error-container">
            <header className="location">
                <div className="location-content">
                    <h2>Drop-in med Vipps</h2>
                </div>
                <img src={Logo} alt="" className="location-logo"/>
            </header>
            <div className="bg-white h-[600px]">
                <div className="flex flex-row justify-center p-10">
                    <img src={CarCrashLogo} alt="" className=""/>
                </div>
                <div className="flex flex-col justify-center items-center gap-3">
                    <h3 className="color text-4xl">Oops — noe gikk galt!</h3>
                    {errorType.valueOf() === ERROR_PAGE_TYPE.CONNECTION_ERROR &&
                        <h4 className="color text-2xl">Unnskyld! Vi har tekniske problemer.</h4>
                    }
                    {errorType.valueOf() === ERROR_PAGE_TYPE.CHARGER_NOT_FOUND &&
                        <h4 className="color text-2xl">Side ikke funnet.</h4>
                    }
                    {errorType.valueOf() === ERROR_PAGE_TYPE.NOT_FOUND &&
                        <h4 className="color text-2xl">Side ikke funnet.</h4>
                    }
                    {errorType.valueOf() === ERROR_PAGE_TYPE.SESSION_NOT_FOUND &&
                        <h4 className="color text-2xl">Ladesession ikke funnet.</h4>

                    }
                    {errorType.valueOf() === ERROR_PAGE_TYPE.VIPPS &&
                        <div className="flex flex-col p-10 gap-5">
                            <h4 className="text-[#767676] text-2xl">Vipps sin betalingsløsning er ikke tilgjengelig for øyeblikket.</h4>
                            <h4 className="text-[#767676] text-2xl">Bruk SMS for å lade som drop-in-kunde. Se instruksjon på lader for mer informasjon.</h4>
                            <h4 className="text-[#767676] text-2xl">Du kan også laste ned Mer Connect-appen og få vårt beste pris som registrert kunde.</h4>
                            <a href={getMerConnectAppUrl()} className="text-[#0000C2] underline text-2xl">Last ned Mer Connect-appen her!</a>
                        </div>

                    }
                    {errorType.valueOf() === ERROR_PAGE_TYPE.CHARGER &&
                        <div className="flex flex-col gap-3 p-10">
                            <h4 className="text-[#767676] text-3xl pb-3">Hva kan dette skyldes?</h4>
                            <h4 className="text-[#767676] text-2xl">1. Ladekabelen er ikke plugget ordentlig i bilen</h4>
                            <h4 className="text-[#767676] text-2xl">2. Du har startet feil ladekontakt</h4>
                            <h4 className="text-[#767676] text-2xl">3. Ladeøkten har timet ut. Gjenta ladeprosedyre for å starte ladingen</h4>
                            <h4 onClick={() => registerSupportCall()} className="text-[#767676] text-2xl">Dersom du fortsatt ikke får startet ladingen, ta kontakt med vår kundeservice på <a href="tel:47670800" className="text-[#0000C2] underline text-2xl">47 67 08 00</a>.</h4>
                            <h4 className="text-[#767676] text-2xl">Et eventuelt reservert beløp hos Vipps blir sluppet.</h4>
                            <div className="flex flex-row justify-center w-full pt-10">
                                <a href={`${APP_URL}/vipps/${localStorage.getItem(MER_EVSE_ID) || ""}`} className="try-again-button text-center text-4xl">Prøv på nytt</a>
                            </div>

                        </div>

                    }

                </div>
            </div>
        </div>
    );
}